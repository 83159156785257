import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Files from "react-files";
import {
  formatSize,
  generateAudioFileName,
  getSessionJwt,
} from "../util/utilities";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { ENDPOINTS } from "../util/constants";
import Markdown from "react-markdown";
import CloseIcon from "@mui/icons-material/Close";
import StopIcon from "@mui/icons-material/Stop";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const FileUpload = ({
  type,
  maxFiles,
  additionalPrompt,
  setAdditionalPrompt,
  customText = "",
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [uploadStatus, setUploadStatus] = useState("");
  const [generatedReport, setGeneratedReport] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [files, setFiles] = useState([]);

  const handleFileUpload = async (event, url) => {
    event.preventDefault();
    if (loadingUpload || files.length < 1) return;
    setLoadingUpload(true);
    setUploadStatus("");

    const formData = new FormData();
    const uniqueFiles = [];
    const fileNames = new Set();

    files.forEach((file) => {
      if (!fileNames.has(file.name)) {
        fileNames.add(file.name);
        uniqueFiles.push(file);
      }
    });

    uniqueFiles.forEach((file) => {
      formData.append("files", file);
    });

    if (additionalPrompt) {
      formData.append("additionalPrompt", additionalPrompt);
    }

    try {
      const jwtToken = await getSessionJwt();
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      // console.log("Response:", response.data);

      if (
        response?.data?.results?.success === true &&
        response?.data?.results?.report
      ) {
        setUploadStatus("File uploaded successfully!");
        setGeneratedReport(response.data.results.report);
      }
    } catch (error) {
      setUploadStatus("File upload failed.");
      console.error("Error:", error);
    }
    setLoadingUpload(false);
  };

  const DownloadButton = () => {
    const [open, setOpen] = useState(false);
    const [editReportPrompt, setEditReportPrompt] = useState("");
    const downloadPDF = () => {
      const element = document.querySelector(".message-content");
      if (element) {
        const html = element.innerHTML;
        const pdfContent = htmlToPdfmake(html);
        const documentDefinition = { content: pdfContent };
        pdfMake.createPdf(documentDefinition).download("report.pdf");
      }
      setOpen(false);
    };

    const handleEditReport = async (event) => {
      event.preventDefault();
      if (loadingUpload || !editReportPrompt || !generatedReport || !type)
        return;
      setLoadingUpload(true);
      setUploadStatus("");

      const formData = new FormData();

      formData.append("report", generatedReport);
      formData.append("reportType", type);
      formData.append("editReportPrompt", editReportPrompt);

      try {
        const jwtToken = await getSessionJwt();

        const response = await axios.post(
          ENDPOINTS.EC2_ENDPOINT + "/assistants/edit-report",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (
          response?.data?.results?.success === true &&
          response?.data?.results?.report
        ) {
          setUploadStatus("File edited successfully!");
          setGeneratedReport(response.data.results.report);
        }
      } catch (error) {
        setUploadStatus("Edit Report failed.");
        console.error("Error:", error);
      }
      setLoadingUpload(false);
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={!generatedReport || loadingUpload}
          onClick={() => setOpen(true)}
          sx={{
            height: "45px",
            width: isMobile ? "300px" : "600px",
          }}
        >
          Open Report Preview
        </Button>
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Report Preview
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{ minHeight: "140px", overflowY: "auto" }}
          >
            <Stack alignItems="center">
              <TextField
                value={editReportPrompt}
                label="Add any additional edit requests to the report here and re-submit the report"
                variant="outlined"
                multiline
                rows={3}
                sx={{ width: "500px" }}
                onChange={(event) => setEditReportPrompt(event.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogContent dividers sx={{ overflowY: "auto" }}>
            {generatedReport && (
              <Markdown className="message-content">{generatedReport}</Markdown>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!generatedReport || !editReportPrompt || loadingUpload}
              onClick={handleEditReport}
            >
              Submit for Edits
            </Button>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              disabled={!generatedReport}
              onClick={downloadPDF}
            >
              Download Report As PDF
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleChange = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileRemove = (fileIndex) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== fileIndex));
  };

  const handleClearFiles = () => {
    setFiles([]);
  };
  const recorderControls = useAudioRecorder();

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = recorderControls;

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    const fileName = generateAudioFileName();
    const fileWithProperties = new File([blob], fileName, {
      type: "video/webm",
    });

    handleChange([fileWithProperties]);
  };

  return (
    <Stack alignItems="center">
      <Stack direction="row">
        <Stack>
          {type === "audio" && (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AudioRecorder
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                onRecordingComplete={addAudioElement}
                recorderControls={recorderControls}
                showVisualizer={true}
                // downloadOnSavePress={true}
              />
              {isRecording && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={stopRecording}
                  sx={{
                    mr: 1,
                    height: "40px",
                    width: "300px",
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "#800000",
                    },
                  }}
                >
                  <StopIcon />
                  {`Stop & Save Recording`}
                </Button>
              )}
              <Typography variant="body1" sx={{ mr: 1 }}>
                Click the mic icon to start recording
              </Typography>
              <Typography variant="body1" sx={{ mr: 1 }}>
                or bring over your pre-recorded session
              </Typography>
              <Typography variant="body1" sx={{ mr: 1 }}>
                by following the instructions below
              </Typography>
            </Stack>
          )}
          <Files
            style={{
              height: "100px",
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
              padding: "10px",
              border: "1px dashed #D3D3D3",
              width: "300px",
            }}
            accepts={type === "audio" ? ["audio/*", ".webm"] : ["image/*"]}
            onChange={handleChange}
            multiple
            maxFiles={maxFiles}
            maxFileSize={100000000}
            minFileSize={0}
            clickable
          >
            {(isDragging) => (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  background: isDragging ? "red" : "white",
                  border: "1px dashed #D3D3D3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {customText.length > 0
                  ? isDragging
                    ? "Drop Here!"
                    : customText
                  : `Drop ${
                      type === "audio" ? "Audio" : "Image"
                    } files here or click to upload`}
              </Box>
            )}
          </Files>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={files.length < 1}
              onClick={handleClearFiles}
              sx={{ mr: 1, height: "40px", width: "300px" }}
            >
              Remove All Files
            </Button>
          </Box>

          {files.length > 0 && (
            <Box sx={{ mt: 3, width: 300 }}>
              <List>
                {files.map((file, i) => (
                  <ListItem
                    key={i}
                    sx={{ display: "flex", alignItems: "center", padding: 1 }}
                  >
                    <ListItemAvatar>
                      {file?.preview?.type === "image" ? (
                        <Avatar
                          variant="square"
                          src={file.preview.url}
                          sx={{ width: 60, height: 60 }}
                        />
                      ) : (
                        <Avatar
                          variant="square"
                          sx={{
                            width: 60,
                            height: 60,
                            backgroundColor: "#D3D3D3",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {file.type === "video/webm" ? "webm" : file.extension}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={
                        file.sizeReadable
                          ? file.sizeReadable
                          : formatSize(file.size)
                      }
                      sx={{ paddingLeft: 2 }}
                    />
                    <Box
                      sx={{
                        height: 60,
                        width: 60,
                        cursor: "pointer",
                        backgroundImage: `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTM4IDEyLjgzbC0yLjgzLTIuODMtMTEuMTcgMTEuMTctMTEuMTctMTEuMTctMi44MyAyLjgzIDExLjE3IDExLjE3LTExLjE3IDExLjE3IDIuODMgMi44MyAxMS4xNy0xMS4xNyAxMS4xNyAxMS4xNyAyLjgzLTIuODMtMTEuMTctMTEuMTd6Ii8+PHBhdGggZD0iTTAgMGg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+)`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "30px 30px",
                      }}
                      onClick={() => handleFileRemove(i)}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {/* Mobile View TextField */}
          {isMobile && (
            <TextField
              value={additionalPrompt}
              label="Additional Prompt"
              variant="outlined"
              multiline
              rows={5}
              sx={{ width: "300px" }}
              onChange={(event) => {
                setAdditionalPrompt(event.target.value);
              }}
            />
          )}
        </Stack>

        {/* Non-Mobile View TextField */}
        {!isMobile && (
          <Stack>
            <TextField
              value={additionalPrompt}
              label="Additional Prompt"
              variant="outlined"
              multiline
              rows={10}
              sx={{ width: isMobile ? "300px" : "600px" }}
              onChange={(event) => {
                setAdditionalPrompt(event.target.value);
              }}
            />
          </Stack>
        )}
      </Stack>
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={files.length < 1 || loadingUpload}
          onClick={(event) =>
            handleFileUpload(
              event,
              type === "audio"
                ? ENDPOINTS.EC2_ENDPOINT +
                    "/assistants/generate-report-from-audio"
                : ENDPOINTS.EC2_ENDPOINT +
                    "/assistants/generate-report-from-image"
            )
          }
          sx={{ height: "45px", width: isMobile ? "300px" : "600px" }}
        >
          {loadingUpload ? <CircularProgress size={"26px"} /> : `Upload`}
        </Button>
      </Box>
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body1" sx={{ mt: 2 }}>
          {uploadStatus}
        </Typography>
        <DownloadButton />
      </Box>
    </Stack>
  );
};

export default FileUpload;
